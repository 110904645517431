import React, { useState } from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import Button from '../Button'

import * as styles from './ShareButton.styles'

export interface ShareButtonProps {
  url: string
  title: string
  text: string
  style?: Style
  initialButtonText?: string
  plain?: boolean
}

const PlainButton: React.FC<JSX.IntrinsicElements['button']> = ({
  children,
  ...props
}) => <button {...props}>{children}</button>

const ShareButton: React.FC<ShareButtonProps> = ({
  title,
  text,
  url,
  style,
  plain,
  initialButtonText,
}) => {
  const notSharedIconClassName = 'fas fa-share'
  const sharedIconClassName = 'fas fa-clipboard-check'

  const [buttonText, setButtonText] = useState(initialButtonText ?? 'Share')
  const [iconName, setIconName] = useState(notSharedIconClassName)

  const WrappingComponent = plain ? PlainButton : Button

  const shareData: ShareData = {
    title,
    text,
    url,
  }
  const onCopy = () => {
    setButtonText('Link copied')
    setIconName(sharedIconClassName)
    setTimeout(() => {
      setButtonText(initialButtonText ?? 'Share')
      setIconName(notSharedIconClassName)
    }, 1500)
  }

  const share = navigator.share
  if (navigator.canShare && navigator.canShare(shareData) && share) {
    return (
      <WrappingComponent
        css={style}
        onClick={() => {
          share(shareData).catch(() => {
            navigator.clipboard.writeText(url)
            onCopy()
          })
        }}
      >
        <i css={styles.icon} className={iconName} />
        {buttonText}
      </WrappingComponent>
    )
  } else {
    return (
      <CopyToClipboard text={url} onCopy={onCopy}>
        <WrappingComponent css={style}>
          <i css={styles.icon} className={iconName} />
          {buttonText}
        </WrappingComponent>
      </CopyToClipboard>
    )
  }
}

export default ShareButton
